import * as React from "react";
import { Link } from "gatsby";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";

const NotFoundPage = () => {
  return (
    <Layout>
      <Helmet>
        <title>404 | Sixvees</title>
        <meta name="robots" content="noindex"></meta>
      </Helmet>
      <main>
        <section className="pafge-section">
          <div className="container">
            <div className="col-lg-8 mx-auto text-center">
              <title>Not found</title>
              <h1 className="mb-4">Page not found</h1>
              <p>
                Sorry{" "}
                <span role="img" aria-label="Pensive emoji">
                  😔
                </span>{" "}
                we couldn’t find what you were looking for.
                <br />
                <br />
                <Link to="/">Go home</Link>.
              </p>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default NotFoundPage;
